// constants/svgConstants.tsx
import React from "react";

export const HeartSVG = () => (
  <svg   
   className="stroke-current group-hover:stroke-white"
  width="100%"
  height="342"
  fill="none"
  xmlns="http://www.w3.org/2000/svg" 
  viewBox="0 0 121.88 139.07"
>
  <path d="m105.06,76.46c.97,23.69-9.25,55.71-23.83,60.2-24.58,7.58-46.11-16.86-55.62-38.49"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
    <path d="m90.56,41.43c2.94,2.76,5.83,7.02,7.92,10.8"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
    />
    
  <path d="m41.74,65.99s14.46,4.69,23.6-23.41c1.12-3.45,2.61-6.77,4.43-9.88,2.66-4.56,6.04-8.69,10.04-12.18,2.73-2.38,5.86-4.6,9.38-6.29.53-.25,1.06-.5,1.6-.72.9-.38,1.92-.37,2.84-.03,3.56,1.3,12.04,5.52,11.8,16.08-.04,1.66-1.1,3.13-2.67,3.66-.52.18-1.12.4-1.78.69-4.08,1.78-10.26,5.49-16.27,15.09"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m89.18,14.22s-.28,13.6,11.8,19.67"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m40.4,47.81c-3.83-6.75-3.1-14.64-2.02-19.58.35-1.63.75-2.94,1.03-3.79.22-.66.02-1.39-.5-1.86l-6.25-5.67c-.77-.7-.78-1.9-.02-2.61l4.61-4.32c.66-.62,1.68-.64,2.37-.05l5.45,4.65c1.26,1.08,3.18-.01,2.9-1.65l-1.32-7.79c-.15-.88.38-1.74,1.24-2l4.94-1.47c.87-.26,1.8.18,2.15,1.03l3.04,7.43c.61,1.5,2.75,1.46,3.31-.06l2.92-7.91c.31-.85,1.22-1.33,2.11-1.11l5.66,1.45c.91.23,1.48,1.14,1.3,2.07l-2.02,10.16c-.14.69.15,1.4.73,1.8,1.84,1.28,5.36,3.03,5.43,5.31"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m19.51,47.12c-.43-.86-9.3-11.74-9.3-11.74,0,0,7.68-11.65,14.05-12.37l12.94,15.77"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m102.47,55.23h13.82c2.28.19,4.02,2.1,4.02,4.38v1.36c0,2.11-1.49,3.92-3.56,4.32l-6.61,1.29"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m111.39,72.96l7.63,1.76c1.24.23,2.05,1.43,1.82,2.66l-.37,1.98c-.29,1.51-1.51,2.67-3.04,2.86l-12,1.52"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m14.31,74.95l-10.49,4.04c-2.13.82-3.28,3.14-2.65,5.33l.38,1.3c.58,2.02,2.52,3.35,4.62,3.17l10.32-1.04"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m18.64,92.78l-12.06,5.6c-1.12.57-1.58,1.94-1.01,3.06l.91,1.8c.69,1.37,2.19,2.14,3.71,1.91l17.4-2.88"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m19.51,59.36c.66-4.67.91-8.79.08-14.48-.43-2.94.92-5.95,3.36-7.42,2.35-1.42,5.59-2.05,9.32.91,1.91,1.51,2.7,4.17,2,6.67l-.31,3.95"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m20.23,40.55s6.75,4.98,13.74,1.8"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m20.67,57.85c-.91,1.1-1.74,2.28-2.46,3.52-.78,1.25-1.45,2.59-2,3.96-5.34,13.2,1.5,30.91,8.73,32.53,3.37.76,4.01-4.48,4.66-6.62,1.93-6.32,4.66-12.33,7.84-18.06,3.05-5.48,6.33-11.08,11.13-15.21,1.69-1.46,5.4-4.43,3.4-6.88-4.57-5.62-22.54-3.77-31.31,6.76Z"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m94.01,50.14c12.2,4.07,19.76,19.36,16.3,25.39-.82,1.42-2.3,1.39-3.79.92-1.27-.4-2.54-1.11-3.41-1.53-3.78-1.79-7.44-3.18-11.63-3.36-3.13-.13-6.79.42-9.79-.68-2.59-.94-4.65-2.89-5.77-5.46-2.83-6.48,1.62-14.67,8.31-16.12,3.24-.7,6.65-.22,9.77.83Z"
    // a
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2px"
  />
  
  <path d="m54.07,62.15s-3.43-1.46-5.49-4.17"/>
</svg>
);