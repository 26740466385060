
import { 
  Atom, 
  HeartCrack, 
  SquareActivity,
  HeartPulse,
  Activity,
  Syringe,
  HandHeart, 
} from "lucide-react";
import { HeartSVG } from "@/components/ui-heart/HeartSVG";
import { VeinSVG } from "@/components/ui-vein/VeinSVG";
import { VascularSVG } from "@/components/ui-vascular/VascularSVG";

export const threecardservices = [
  {
    id: 0,
    title: "Advanced Heart Care",
    styledtitle: <span className="text-red-500">Heart<span className="text-black">{" "}Care</span></span>,
    description: `Our Heart Care services encompass the latest technologies and techniques to diagnose and treat a wide range of heart conditions, including coronary artery disease, heart failure, valvular heart diseases, and arrhythmias. Whether you require a minimally invasive procedure or long-term management, our team will provide you with a personalized care plan designed to address your unique needs and improve your quality of life.`,
    // icon: <AiOutlineCode className="h-8 w-8 text-gray-500" />,
      icon: <Atom color="white"/>,
      link: "/heart-care",
      svg: <HeartSVG />,
      images: "images/clinic/performing-checkups.jpg",
      textcolor: "text-red-600",
  },
  {
    id: 1,
    title: "Vein Care",
    cardstyle:"block mb-3 border rounded-3xl bg-white text-slate-600 hover:bg-blue-800 hover:text-white",
    styledtitle: <span className="text-blue-800">Vein<span className="text-black">{" "}Care</span></span>,
    description: `
    Our Vein Department offers a range of advanced treatments to help you overcome vein conditions such as varicose veins, spider veins, and chronic venous insufficiency. Our in-office vein ablation procedures, like vein ablation procedures, VenaSeal, and radiofrequency ablation (RFA), allow for quick recovery times and minimal downtime, getting you back to your daily routine.`,
    // icon: <BsPen className="h-8 w-8 text-gray-500" />,
    icon: <HeartCrack color="blue"/>,
    link: "/vein-care",
    svg: <VeinSVG />,
    images: "/images/clinic/Varicose-Veins-Treatment -cropped 480 2.webp",
    textcolor: "text-blue-800",
  },
  {
    id: 2,
    title: "Vascular Care",
    styledtitle: <span className="text-blue-500">Vascular<span className="text-black">{" "}Care</span></span>,
    description: `Our Heart Care services encompass the latest technologies and techniques to diagnose and treat a wide range of heart conditions, including coronary artery disease, heart failure, valvular heart diseases, and arrhythmias. Whether you require a minimally invasive procedure or long-term management, our team will provide you with a personalized care plan designed to address your unique needs and improve your quality of life.
    `,
    // icon: <BiRocket className="h-8 w-8 text-gray-500 stroke-gray-500" />,
    cardstyle: "block mb-3 border rounded-3xl bg-white text-slate-600 hover:bg-red-600 hover:text-white",
    icon: <SquareActivity color="blue"/>,
    link: "/vascular-care",
    svg: <VascularSVG />,
    images: "/images/clinic/cypress-cardiovascular-institute-facility.jpg",
    textcolor: "text-purple-800",
  },
];
