import React from "react";
// import Link from "next/link";

export const Departments = ({ 
  titleCard,
  primaryHeading,
  linkOne,
  headingOne,
  descriptionOne,
  linkTwo,
  headingTwo,
  descriptionTwo,
  linkThree,
  headingThree,
  descriptionThree
 }: any) => {
  return (
    <section className="py-12 md:py-24 -mx-6">
  <div className="container mx-auto px-4">
    <div className="max-w-xl mx-auto md:max-w-none">
      <h2 className="text-left leading-tight font-heading tracking-tighter text-[2.5rem] sm:text-[2.81rem] md:text-5xl lg:text-[3.5rem] xl:text-6xl capitalize mb-6">{titleCard || ``}</h2>
      <h3 className="tracking-tighter text-white text-[2.1rem] sm:text-[2.3rem] md:text-[2.45rem] lg:text-[2.75rem] xl:text-5xl leading-tight capitalize">{primaryHeading || ``}</h3>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6 lg:mb-0">
          <a className="relative group block h-full w-full px-9 py-14 hover:bg-white bg-blue-800 border rounded-3xl transition duration-300" href={linkOne || `/vein-care-101`}>
            <span className="hidden group-hover:block absolute top-0 right-0 mt-16 mr-8">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 2L2 22" stroke="white" strokeWidth="3.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M22 20.3393V2H3.66071" stroke="white" strokeWidth="3.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </span>
            <div>
              <div className=" pr-10">
                <h5 className="text-3xl xs:text-4xl group-hover:text-black text-white mb-6">
                  {headingOne || `Vein Care 101`}
                </h5>
              </div>
              <p className="group-hover:text-black text-white">
                {descriptionOne || ``}
              </p>
              <span className='inline-flex items-center mt-4 text-white group-hover:text-black'>
                  See More
                  <svg
                    viewBox='0 0 20 20'
                    className='ml-1.5 h-5 w-5 fill-slate-400'
                    fillRule='evenodd'
                    clipRule='evenodd'
                  >
                    <path d='M7 3.25H3.25v13.5h13.5V13h-1.5v2.25H4.75V4.75H7v-1.5Zm9.75 0H10v1.5h4.19l-5.72 5.72 1.06 1.06 5.72-5.72V10h1.5V3.25Z'></path>
                  </svg>
                </span>
            </div>
          </a>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6 lg:mb-0">
          <a href={linkTwo || `/vascular-care-101`} className="relative group block h-full w-full px-9 py-14 hover:bg-white bg-purple-800 border rounded-3xl transition duration-300">
            <span className="hidden group-hover:block absolute top-0 right-0 mt-16 mr-8">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 2L2 22" stroke="white" strokeWidth="3.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M22 20.3393V2H3.66071" stroke="white" strokeWidth="3.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </span>
            <div>
              <div className="pr-10">
                <h5 className=" text-3xl xs:text-4xl group-hover:text-black text-white mb-6">{headingTwo || `Vascular 101`}</h5>
              </div>
              <p className="group-hover:text-black text-white">{descriptionTwo || ``}</p>
              <span className='inline-flex items-center mt-4 text-white group-hover:text-black'>
                  See More
                  <svg
                    viewBox='0 0 20 20'
                    className='ml-1.5 h-5 w-5 fill-slate-400'
                    fillRule='evenodd'
                    clipRule='evenodd'
                  >
                    <path d='M7 3.25H3.25v13.5h13.5V13h-1.5v2.25H4.75V4.75H7v-1.5Zm9.75 0H10v1.5h4.19l-5.72 5.72 1.06 1.06 5.72-5.72V10h1.5V3.25Z'></path>
                  </svg>
                </span>
            </div>
            </a>
        </div>
        <div className="w-full lg:w-1/3 px-3">
          <a className="relative group block h-full w-full px-9 py-14 hover:bg-white bg-red-600 border  rounded-3xl transition duration-300" href={linkThree || ``}>
            <span className="hidden group-hover:block absolute top-0 right-0 mt-16 mr-8">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 2L2 22" stroke="white" strokeWidth="3.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M22 20.3393V2H3.66071" stroke="white" strokeWidth="3.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </span>
            <div>
              <div className=" pr-10">
                <h5 className="text-3xl xs:text-4xl group-hover:text-black text-white mb-6">{headingThree || `Heart Care 101 `}</h5>
              </div>
              <p className="group-hover:text-black text-white">{descriptionThree || ``}</p>
              <span className='inline-flex items-center mt-4 text-white group-hover:text-black '>
                  See More
                  <svg
                    viewBox='0 0 20 20'
                    className='ml-1.5 h-5 w-5 fill-slate-400'
                    fillRule='evenodd'
                    clipRule='evenodd'
                  >
                    <path d='M7 3.25H3.25v13.5h13.5V13h-1.5v2.25H4.75V4.75H7v-1.5Zm9.75 0H10v1.5h4.19l-5.72 5.72 1.06 1.06 5.72-5.72V10h1.5V3.25Z'></path>
                  </svg>
                </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}