import React from "react";

export const VeinSVG = () => (
  <svg 
    className="stroke-current group-hover:stroke-white"
    width="100%"
    height="342"
    fill="none"
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 69.48 68.01"
  >
    <path 
      d="m28.45,25.37v36.22c0,2.99,2.43,5.42,5.42,5.42s5.42-2.43,5.42-5.42l.1-17.08"
      // stroke="#0056b3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path 
      d="m39.4,37.2l-.1-30.78c0-2.99-2.43-5.42-5.42-5.42s-5.42,2.43-5.42,5.42v11.81"
      // stroke="#0056b3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path 
      d="m28.45,61.06s5.27-4.33,10.84,0"
      // stroke="#0056b3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path 
      d="m28.45,18.23l-7.25,3.6S12.54,14.64,2.58,15.11c0,0-1.89,0-1.53,2.77.02.18,10.9-.29,16.33,6.07,0,0-11.79,9.49-16.33,18.15,0,0,.47,2,3.24,2.83l7.07-9.25s2.12,5.3,1.24,11.49c0,0,1.77,1.59,3.42,0,0,0,1.18-10.79-1.59-14.73,0,0,9.67-6.42,14.03-7.07"
      // stroke="#0056b3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path 
      d="m39.4,37.2s5.61,11.73,13.81,5.07c0,0,.24-9.25,3.18-8.78,0,0,2.95-.27,1.77,2.67l-.94,4.05,4.82-4.47c.58-.54,1.46-.61,2.1-.16.53.38.99.98.79,1.91l-2.36,2.83s2.01-.16,3.34-.85c.97-.5,2.22-.03,2.5,1.02.11.41.1.91-.12,1.53,0,0-2.77,4.01-9.67,1.53l-2.3,1.83s5.54,3.3,5.36,5.89c0,0-.08,1.72-1.58,1.88-.57.06-1.12-.16-1.55-.54-1-.87-3.35-2.87-5.07-3.94-.76-.47-1.66-.66-2.55-.57-2.64.26-9.85.57-11.54-3.61"
      // stroke="#0056b3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);